// import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import React, { Component } from "react"

import AniLink from "gatsby-plugin-transition-link/AniLink";

import { graphql } from "gatsby"

import lazySizes from 'lazysizes'

import "../styles/style.scss"

class Fashion extends Component {


    constructor(props) {
        super(props);
        this.modelViewerFashion = React.createRef();
    }

    state = {
        posterFashion: '',
        valueLoaded: '0'
    }

    componentDidMount() {
      if (typeof window !== `undefined`) {
          window.hello=() => {document.location.href = "mailto:hello@gaspardbruno.com";console.log("Send us an email!");return "";}
          setTimeout(console.log.bind(console, "Hello, you're interested in what's going on behind? We'd love to hear from you at " +   '%chello@gaspardbruno.com!' + '%c -> ' + '%chello()', 'color: #1812d6', 'color:black', 'font-weight:bold'), 0);
      }
      try {

        require('lazysizes/plugins/attrchange/ls.attrchange')
        require('lazysizes/plugins/unveilhooks/ls.unveilhooks.js')


        require("@google/model-viewer/dist/model-viewer")

        } catch (e) {
            console.error(e)
        }



    }




    render() {

        const data = this.props.data?.wpgraphql?.pages?.nodes[0]

        return (

        <Layout>

            <style>
            @import url('https://fonts.googleapis.com/css2?family=Amiri:ital@1&display=swap');
            </style>

            <SEO title="Polygon Dynamics"/>
            <div className="fashionPageVideo marginSection">
                    <video preload="buffer" playsInline loop muted autoPlay>
                        <source src={data.fashion.videointro.mediaItemUrl} type="video/mp4"></source>
                    </video>

            </div>
            <div className="fashionPage">
                <div className="row row___max marginSection">
                    <div className="col col4">
                        <p className="texte marginBottom">From efficient AR-enabled Products pages that give users a concrete idea of sizing and texture, to proper physics applied to cloth simulation; the narrative is always the key aspect we push forward. Now more than ever we have to invent ways to let people experience garments & textures beyond physical touch.</p>

                        <p className="texte marginBottom">One single 3D asset can be deployed Cross platforms (Web, iOS & Android) + Augmented Reality experience.</p><br></br>


                    </div>

                </div>
                <div className="row marginSection">
                    <div className="fashionPageImage col4">
                        <div className="lazyloadState lazyload" style={{backgroundImage: `url(${data.fashion.image1.small})` }} data-bg={data.fashion.image1.large}></div>
                    </div>
                    <div className="fashionPageImage col4">
                        <div className="lazyloadState lazyload" style={{backgroundImage: `url(${data.fashion.image1.small})` }} data-bg={data.fashion.image2.large}></div>
                    </div>

                </div>
                <div className="row row___max marginSection">
                    <div className="col col4">
                        <p className="texte marginBottom">This is the flow we use:</p>

                        <p className="texte marginBottom">→ Creation of the 3D model of the product<br></br>
                        → Texturing the model using the pictures of the product only<br></br>
                        → Making cloth simulations or other animations needed - We provide Standing, Walking & Running animation for Female, Male, Neutral & Kids; from XS to XXL</p>

                        <p className="texte marginBottom">→ in a matter of days only</p>
                    </div>
                </div>
                <div className="row row___max marginSection customSection">
                    <div className="col col8">
                        <h3 className="titleSerif">Playground<br></br>Research & Development</h3>
                    </div>
                    <model-viewer id="custom" camera-controls disable-zoom auto-rotate min-field-of-view="45deg" max-field-of-view="45deg" min-camera-orbit="auto 49deg 1.35m" max-camera-orbit="auto 133deg 1.35m" shadow-intensity="0" touch-action="pan-y" src="/chiquito.glb"></model-viewer>
                    <div className="col col8">
                        <h3 className="title">POLYGON<br></br>DYNAMICS</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="fashionPageImage col4">
                        <div className="lazyloadState lazyload" style={{backgroundImage: `url(${data.fashion.image1.small})` }} data-bg={data.fashion.image3.large}></div>
                    </div>
                    <div className="fashionPageImage col4">
                        <div className="lazyloadState lazyload" style={{backgroundImage: `url(${data.fashion.image1.small})` }} data-bg={data.fashion.image4.large}></div>
                    </div>

                </div>

                <div className="row">
                    <div className="fashionPageImage col4">
                        <div className="lazyloadState lazyload" style={{backgroundImage: `url(${data.fashion.image1.small})` }} data-bg={data.fashion.image5.large}></div>
                    </div>
                    <div className="fashionPageImage col4">
                        <div className="lazyloadState lazyload" style={{backgroundImage: `url(${data.fashion.image1.small})` }} data-bg={data.fashion.image6.large}></div>
                    </div>

                </div>

            </div>

        </Layout>

        )
    }

}

export default Fashion


export const fashionQuery = graphql`
query {
    wpgraphql {
        pages(first: 1, where:{ title: "Fashion"} ) {
          nodes {
            title
            content
            fashion {
                videointro{
                    mediaItemUrl
                }
                image1{
                    sourceUrl
                    small: sourceUrl(size: SCOVER)
                    medium: sourceUrl(size: MCOVER)
                    large: sourceUrl(size: LCOVER)
                    xlarge: sourceUrl(size: XLCOVER)
                }
                image2{
                    sourceUrl
                    small: sourceUrl(size: SCOVER)
                    medium: sourceUrl(size: MCOVER)
                    large: sourceUrl(size: LCOVER)
                    xlarge: sourceUrl(size: XLCOVER)
                }
                image3{
                    sourceUrl
                    small: sourceUrl(size: SCOVER)
                    medium: sourceUrl(size: MCOVER)
                    large: sourceUrl(size: LCOVER)
                    xlarge: sourceUrl(size: XLCOVER)
                }
                image4{
                    sourceUrl
                    small: sourceUrl(size: SCOVER)
                    medium: sourceUrl(size: MCOVER)
                    large: sourceUrl(size: LCOVER)
                    xlarge: sourceUrl(size: XLCOVER)
                }
                image5{
                    sourceUrl
                    small: sourceUrl(size: SCOVER)
                    medium: sourceUrl(size: MCOVER)
                    large: sourceUrl(size: LCOVER)
                    xlarge: sourceUrl(size: XLCOVER)
                }
                image6{
                    sourceUrl
                    small: sourceUrl(size: SCOVER)
                    medium: sourceUrl(size: MCOVER)
                    large: sourceUrl(size: LCOVER)
                    xlarge: sourceUrl(size: XLCOVER)
                }
            }
          }
        }
    }
}
`
